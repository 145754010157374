.k-widget-5::-webkit-scrollbar-track
{
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
    border-radius: 10px;
    background-color: #F5F5F5;
}

.k-widget-5::-webkit-scrollbar
{
    width: 7px;
    height:7px;
    background-color: #f5f5f5;
}

.k-widget-5::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    /* -webkit-box-shadow: inset 0 0 6px rgba(194, 194, 194, 0.3); */
    background-color: #e2e2e2a8;
}