.default-scroll::-webkit-scrollbar
{
    width: 5px;
    height:5px;
    background-color: #f5f5f5;
}

.default-scroll::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    /* -webkit-box-shadow: inset 0 0 6px rgba(194, 194, 194, 0.3); */
    background-color: #d8dce67a;
    opacity: 0.5;
}

.default-scroll{
    height: 550px;
    overflow-x: hidden;
    overflow-y: auto;
}