.modal-header{
    height: 30px;
    border-bottom: 1px solid #ebedf2;
    padding: 5px;
    font-size: inherit !important;
    padding-left: 10px;
    font-weight: 500;
}

.modal-footer{
    height: 40px;
    border: none;
}

.bg-success {
    background-color: #00d328 !important;
}

.loading{
    animation-name: example;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.modal-backdrop.show {
    opacity: 0;
}

@keyframes example {
    from {width: 0%;}
    to {width: 100%;}
}