/* .list-database > thead{
    position: sticky !important;
    top: 0 !important;
    background: white !important;
    z-index: 9999999999999999999;
} */

tr:hover {
    background: #e1e1ef2e;
}

.k-datatable__table::-webkit-scrollbar-track
{
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
    border-radius: 10px;
    background-color: #F5F5F5;
}

.k-datatable__table::-webkit-scrollbar
{
    width: 7px;
    height:7px;
    background-color: #f5f5f5;
}

.k-datatable__table::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    /* -webkit-box-shadow: inset 0 0 6px rgba(194, 194, 194, 0.3); */
    background-color: #5578eb;
}


.react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e1e1ef24;
    border-radius: 0.25rem;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
}
  
.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
}

.react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #646c9a;
    font-size: small;
    cursor: pointer;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
    color: #878a8c;
}

.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
}
  
.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu
> .react-contextmenu-item:after {
    content: '▶';
    display: inline-block;
    position: absolute;
    right: 7px;
}

.example-multiple-targets::after {
    content: attr(data-count);
    display: block;
}

.custom-root {
    background-color: #fff;
}

.custom-divider {
    background-color: #000;
}

.custom-selected {
    background-color: #f00;
}
  
.react-contextmenu-wrapper {
    width: 80px;
}

.badge {
    width: 50px;
}

#popoverVerifyPass .popover-header{
    background-color: #5867dd;
    color: white;
    height: 25px;
    padding: 5px 5px !important;
}

.k-sticky-toolbar__item.k-sticky-toolbar__item--builder > a > i {
    color: #5867dd !important;
}

.k-sticky-toolbar__item.k-sticky-toolbar__item--builder:hover > a {
    color: #ffffff !important;
    background-color:  #5867dd !important;
}

.k-sticky-toolbar__item.k-sticky-toolbar__item--builder:hover > a >i {
    color: #ffffff !important;
}