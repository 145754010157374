.autocomplete {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
.autocomplete-items {
  position: absolute;
  border: 1px solid #ebedf2;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items div {
  padding: 0.15rem 1rem;
  font-size: 0.875rem;
  cursor: pointer;
  background-color: #fff; 
  /* border-bottom: 1px solid #d4d4d4;  */
}
  
/*when hovering an item:*/
.autocomplete-items div:hover {
  background-color: #007bff; 
  color: white;
}


.input-icons i {
  position: absolute;
  right: 0px;

}
  
.input-icons {
    width: 100%;
    height: inherit;
}
  
.icon {
  padding: 10px;
  color: inherit;
  height: 30px;
  width: 30px;
  text-align: center;
}

input:focus {
  border-color: #8690f6;
  -webkit-box-shadow: none;
  box-shadow: none;
}