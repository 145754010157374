
/* .k-login-v1--enabled {
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat; 
    height: 100%;linear-gradient(to right, #073bbb, #061483)
} */

.login_txt{
    border: 1px solid #e1e1ef !important;
}

::placeholder {
    color: #e1e1ef !important;
    opacity: 0.7 !important; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #e1e1ef !important;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color: #e1e1ef !important;
  }

.k-page{
    height: 100%;
}

.k-login-v1 {
    webkit-background-size: cover;
    background-size: auto;
    padding: 3rem; 
}

.k-login-v1 .k-login-v1__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap; 
}

.k-login-v1 .k-login-v1__head .k-login-v1__head-logo > a {
    display: inline-block; 
}

.k-login-v1 .k-login-v1__head .k-login-v1__head-signup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
}

.k-login-v1 .k-login-v1__head .k-login-v1__head-signup > h4 {
    margin-bottom: 0;
    color: #717ce6;
    font-size: 1.1rem;
    font-weight: 500;
}

.k-login-v1 .k-login-v1__head .k-login-v1__head-signup > a {
    color: #fff;
    font-size: 1.1rem;
    margin-left: 0.5rem; 
}

.k-login-v1 .k-login-v1__body {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
}
    
.k-login-v1 .k-login-v1__body .k-login-v1__body-section {
    width: 50%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-section .k-login-v1__body-section-info {
    width: 390px;
    margin-right: 7rem; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-section .k-login-v1__body-section-info > h3 {
    color: #fff;
    font-size: 1.93rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    line-height: 2; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-section .k-login-v1__body-section-info > p {
    color: #6C73D8;
    font-weight: 500;
    font-size: 1.1rem; 
}
    
.k-login-v1 .k-login-v1__body .k-login-v1__body-seaprator {
    background-color: #3e4bb9;
    height: 450px;
    width: 1px;
    margin: auto; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper {
    width: 50%; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container {
    max-width: 390px;
    width: 100%;
    margin-left: 7rem; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-title {
    text-align: center;
    color: #fff;
    padding-bottom: 2rem;
    font-size: 1.8rem;
    font-weight: 500; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-form .form-group .form-control {
    border-radius: 40px;
    padding: 2.4rem 2.2rem;
    margin-top: 1.3rem;
    border-color: #4E52A8;
    background-color: transparent;
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-form .form-group > input::-webkit-input-placeholder {
    color: #6C73D8;
    font-weight: 500; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-form .form-group > input:-ms-input-placeholder {
    color: #6C73D8;
    font-weight: 500; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-form .form-group > input::-ms-input-placeholder {
    color: #6C73D8;
    font-weight: 500; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-form .form-group > input::placeholder {
    color: #6C73D8;
    font-weight: 500; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-form .form-group > input {
    color: #fff; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action > a {
    display: inline-block; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action a.k-link {
    margin-left: 1.8rem; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action a.k-link > span {
    color: #fff; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn {
    padding: 1rem 3.5rem;
    border-radius: 60px;
    color: #fff;
    background-color: #ff146c;
    font-size: 1.1rem; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-divider {
    margin: 3rem 0; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-divider .k-divider > span:not(:first-child):not(:last-child) {
    color: #6D74D9;
    font-weight: 500;
    font-size: 1.1rem; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-divider .k-divider > span:first-child {
    border-bottom: 1px solid #4548AC; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-divider .k-divider > span:last-child {
    border-bottom: 1px solid #4548AC; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-options > a {
    border-radius: 60px;
    background-color: #1F2375;
    font-weight: 600;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 1rem 0;
    font-size: 1rem; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-options > a > i {
    font-size: 1.1rem;
    color: #fff; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-options > a:not(:first-child):not(:last-child) {
    margin: 0 2rem;
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-options > a:hover {
    color: #fff;
    background-color: #1F2164; 
}

.k-login-v1 .k-login-v1__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
}

.k-login-v1 .k-login-v1__footer .k-login-v1__footer-link {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; 
}

.k-login-v1 .k-login-v1__footer .k-login-v1__footer-link > a {
    color: #fff;
    font-weight: 500;
    font-size: 0.93rem; 
}

.k-login-v1 .k-login-v1__footer .k-login-v1__footer-link > a:not(:first-child):not(:last-child) {
    margin: 0 2rem; 
}

.k-login-v1 .k-login-v1__footer .k-login-v1__footer-info > a {
    font-weight: 500;
    font-size: 1rem;
    color: #717ce6;
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a; 
}

.k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-action .btn:hover {
    background-color: #ef005a;
}
#k_login_v1 {
    margin-top: 100px !important;
}
@media (max-width: 1024px) {

    .k-login-v1 {
        padding: 3rem 1rem 2rem 1rem; 
    }

    .k-login-v1 .k-login-v1__head .k-login-v1__head-signup > h4 {
        padding-left: 1rem; 
    }

    .k-login-v1 .k-login-v1__body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column; 
    }

    .k-login-v1 .k-login-v1__body .k-login-v1__body-section {
        padding-right: 0;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-top: 3rem; 
    }

    .k-login-v1 .k-login-v1__body .k-login-v1__body-section .k-login-v1__body-section-info {
        width: 380px;
        max-width: 100%;
        text-align: center;
        margin-right: 0; 
    }

    .k-login-v1 .k-login-v1__body .k-login-v1__body-seaprator {
        border: 1px solid #3F46B0;
        height: 2px;
        width: 100%;
        max-width: 500px;
        margin: 2rem auto; 
    }

    .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper {
        padding-left: 0;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; 
    }

    .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container {
        margin-left: 0; 
    }

    .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-title {
        padding-top: 1rem; 
    }

    .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-divider {
        margin: 2rem 0; 
    }

    .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-options {
        margin-top: 3rem; 
    }

    .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-options > a > i {
        padding: 0.4rem 0.3rem 0.4rem 0; 
    }

    .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-options > a:not(:first-child):not(:last-child) {
        margin: 0 1rem; 
    }

    .k-login-v1 .k-login-v1__footer {
        padding-bottom: 2rem; 
    }

    .k-login-v1 .k-login-v1__footer .k-login-v1__footer-info > a {
        padding-right: 0.5rem; 
    }

    .k-login-v1 .k-login-v1__footer .k-login-v1__footer-link > a:not(:first-child):not(:last-child) {
        padding: 0 1rem; 
    } 

}

@media (max-width: 768px) {

    .k-login-v1 .k-login-v1__head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; 
    }

    .k-login-v1 .k-login-v1__head .k-login-v1__head-logo {
        -webkit-box-flex: 1;
        -ms-flex: 1 100%;
        flex: 1 100%;
        padding-left: 3rem;
    }

    .k-login-v1 .k-login-v1__head .k-login-v1__head-signup {
        -webkit-box-flex: 1;
        -ms-flex: 1 100%;
        flex: 1 100%;
        text-align: right;
        padding: 3rem 0 0 3rem; 
    }
        
    .k-login-v1 .k-login-v1__head .k-login-v1__head-signup > span {
            padding-left: 0; 
    }

    .k-login-v1 .k-login-v1__body .k-login-v1__body-wrapper .k-login-v1__body-container .k-login-v1__body-options {
        margin-bottom: 2rem; 
    }

    .k-login-v1 .k-login-v1__footer {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column;
        flex-flow: column; 
    }

    .k-login-v1 .k-login-v1__footer .k-login-v1__footer-link {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
        margin-bottom: 1rem; 
    } 
}

@media (min-width: 360px){
    .k-login-v1--enabled {
        height: 100% !important;
    }

    .k-login-v1__body-wrapper {
        margin: 60px auto auto !important;
    }

    .k-login-v1__head-logo{
        display: contents !important;
    }

    .k-login-v1__head-logo a{
        margin: auto !important;
    }
}

@media (min-width: 280px) and (min-height: 653px){
    .k-login-v1__body-action{
        display: block !important;
    }

    .k-login-v1__head-logo{
        padding: 0;
        display: contents;
    }

    .k-login-v1__head-logo a{
        margin: auto;
    }

    .k-login-v1__body-action button{
        width: 100%;
        margin-top: 30px;
    }
}

@media (min-width: 375px) and (min-height: 812px){
    .k-login-v1__body-wrapper {
        margin: 120px auto auto !important;
    }
}

@media (min-width: 411px) and (min-height: 823px){
    .k-login-v1--enabled {
        height: 100%
    }

    .k-login-v1__body-wrapper {
        margin: 130px auto auto !important;
    }
}

@media (min-width: 411px) and (min-height: 731px){
    .k-login-v1--enabled {
        height: 100%
    }

    .k-login-v1__body-wrapper {
        margin: 90px auto auto !important;
    }
}

@media (min-width: 568px){
    .k-login-v1--enabled {
        height: auto !important
    }

    .k-login-v1__body-wrapper {
        margin: 20px auto auto !important;
    }
}

@media (min-width: 640px){
    .k-login-v1--enabled {
        height: auto !important
    }

    .k-login-v1__body-wrapper {
        margin: 10px auto auto !important;
    }
}

@media (min-width: 720px) and (min-height: 540px){
    .k-login-v1--enabled {
        height: 100% !important;
    }
}

@media (min-width: 768px) and (min-height: 1024px){
    .k-login-v1--enabled {
        height: 100% !important;
    }
}

@media (min-width: 1024px) and (min-height: 768px){
    .k-login-v1--enabled {
        height: 100% !important;
    }
}

@media (min-width: 1024px) and (min-height: 1366px){
    .k-login-v1--enabled {
        height: 100% !important;
    }
}

@media (min-width: 1366px) and (min-height: 1024px){
    .k-login-v1--enabled {
        height: 100% !important;
    }

    .k-login-v1__body-wrapper {
        margin: 240px auto auto !important;
    }
}
